import * as Sentry from "@sentry/node";
import axios from "axios";

export const fetcher = async (method, url, params, body) => {
  try {

    return await axios({
      method: method,
      baseURL: process.env.NEXT_PUBLIC_BE_KEY_VARIABLE,
      url: url,
      params: params,
      data: body,
    }).then((res) => res.data);
  }
   catch (error) {
    Sentry.captureException(error);
    return error.response.status;
  }
};

export default fetcher;
