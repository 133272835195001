import DateFnsUtils from '@date-io/date-fns';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { createMuiTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Event from '@material-ui/icons/Event';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import 'date-fns';
import elLocale from 'date-fns/locale/el';
import React, { useState } from 'react';

const pickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#212121',
    },
  },
});

const DatePickerWithClearIcon = (props) => {
  const {
    handleStartDateChange,
    handleEndDateChange,
    selectedStartDate,
    selectedEndDate,
    handleErrorOnStartDate,
    handleErrorOnEndDate,
    labelFrom,
    labelTo,
    fullWidth,
  } = props;
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={elLocale}>
      <Grid
        id='grid-dates'
        container
        justify='center'
        alignItems='center'
        style={{ marginRight: 10, flexWrap: 'nowrap' }}
      >
        <ThemeProvider theme={pickerTheme}>
          <KeyboardDatePicker
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: 80,
              marginLeft: 0,
              marginBottom: 0,
              marginTop: 0,
              width: fullWidth ? '100%' : '',
            }}
            disableToolbar
            variant='inline'
            margin='normal'
            id='date-picker-dialog'
            label={labelFrom}
            format='dd/MM/yyyy'
            value={selectedStartDate}
            onChange={(date) => handleStartDateChange(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            open={openStartDate}
            onClose={() => {
              setOpenStartDate(false);
            }}
            invalidDateMessage='Μη έγκυρη ημερομηνία'
            onError={(error) => handleErrorOnStartDate(error)}
            maxDateMessage='Μη έγκυρη ημερομηνία'
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <IconButton
                    aria-label='clear-start-date-icon'
                    id='icon-button'
                    style={{ margin: '-0.3em' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStartDateChange(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    aria-label='choose-start-date-icon'
                    onClick={() => setOpenStartDate(true)}
                    style={{ margin: '-0.3em' }}
                  >
                    <Event />
                  </IconButton>
                </React.Fragment>
              ),
            }}
            InputAdornmentProps={{
              position: 'start',
              style: {
                display: 'none',
              },
            }}
          />
        </ThemeProvider>
      </Grid>
      <Grid
        container
        justify='center'
        alignItems='center'
        style={{ marginLeft: 0, marginRight: 10, flexWrap: 'nowrap' }}
      >
        <ThemeProvider theme={pickerTheme}>
          <KeyboardDatePicker
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: 80,
              marginLeft: 0,
              marginBottom: 0,
              marginTop: 0,
              width: fullWidth ? '100%' : '',
            }}
            disableToolbar
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            id='date-picker-inline'
            label={labelTo}
            value={selectedEndDate}
            minDate={selectedStartDate ? selectedStartDate : undefined}
            minDateMessage='Επιλέξτε μεταγενέστερη ημερομηνία '
            onChange={(date) => handleEndDateChange(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            open={openEndDate}
            onClose={() => {
              setOpenEndDate(false);
            }}
            invalidDateMessage='Μη έγκυρη ημερομηνία'
            onError={(error) => handleErrorOnEndDate(error)}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <IconButton
                    aria-label='clear-end-date-icon'
                    id='icon-button'
                    style={{ margin: '-0.3em' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEndDateChange(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    aria-label='choose-end-date-icon'
                    onClick={() => setOpenEndDate(true)}
                    style={{ margin: '-0.3em' }}
                  >
                    <Event />
                  </IconButton>
                </React.Fragment>
              ),
            }}
            InputAdornmentProps={{
              position: 'start',
              style: {
                display: 'none',
              },
            }}
          />
        </ThemeProvider>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerWithClearIcon;
